jQuery.noConflict();
(function($) {

    $(document).ready(function() {

    /*hide-header*/
    $(window).scroll(function($) {
        jQuery('.header').toggleClass('header-shadow', jQuery(this).scrollTop() > 75);
    });
    /*hide-header*/

    $('#headerformit-form').append('<input type="text" name="support" value="" class="_org" style="visability:hidden; height: 0; width: 0; padding: 0; border:none;"/>')

    // Send header-modal-form
	$('#submit_modal').on('click', function (e) {
		e.preventDefault();
		$('.errors').remove();
		var form = $('#header-modal-form');
 		var email = form.find('input[name="email-index"]').val();
		var tel = 	form.find('input[name="tel-index"]').val();
		var name = 	form.find('input[name="name"]').val();
		var errMsg = 'Необходимо заполнить поля.';

			if(email != '' && tel != '' && name != '') {
				var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i;

				if(pattern.test(email)){
					var resCap = grecaptcha.getResponse(widgetIdM);
					if (resCap != "" && resCap != undefined && resCap.length > 0){
						var m_method=form.attr('method');
						var m_action=form.attr('action');
						var m_data=form.serialize();
						$.ajax({
							type: m_method,
							url: m_action,
							data: m_data,
							success: function(result){
								$(".modal-body").html('<div class="thanks">Ваш запрос отправлен!<br>Менеджер свяжется с Вами в ближайшее время, и ответит на Ваши вопросы.<br>Спасибо!</div><div class="thanks-close"><a href="#" data-dismiss="modal" class="modal-close-link">Закрыть</a></div>');
							}
						});
						return false;
					} else errMsg = 'Неправильно заполнено поле проверки';
				} else errMsg = 'Неверный адрес электронной почты!';
			}
		form.parent().before('<div class="errors"><span>' + errMsg + '</span></div>');
		return false;
    });

    // To top

    // declare variable
    var scrollTop = $("#top-page");

    $(window).scroll(function() {
        // declare variable
        var topPos = $(this).scrollTop();
        // if user scrolls down - show scroll to top button
        if (topPos > 100) {
        $(scrollTop).css("opacity", "1");
        } else {
        $(scrollTop).css("opacity", "0");
        }
    }); // scroll END

    //Click event to scroll to top
    $(scrollTop).click(function() {
        $('html, body').animate({
        scrollTop: 0
        }, 800);
        return false;

    }); // click() scroll top EMD

    AOS.init({
        disable: 'phone',
    });

    $(".j-counter").countimator();

    new Swiper(".j-review-slider", {
            slidesPerView: 2,
            spaceBetween: 65,
            loop: !0,
            // autoHeight: true,
            // effect: 'flip',
            autoplay: {
                delay: 4500,
                disableOnInteraction: !1
            },
            navigation: {
                nextEl: ".j-review-next",
                prevEl: ".j-review-prev"
            },
            breakpoints: {
                480: {
                slidesPerView: 1,
                autoHeight: true,
                },
                640: {
                slidesPerView: 1,
                autoHeight: true,
                },
                768: {
                slidesPerView: 1,
                autoHeight: true,
                },
                992: {
                slidesPerView: 2,
                }
            },
        });

        new Swiper(".j-service-slider", {
            slidesPerView: 3,
            spaceBetween: 30,
            loop: !0,
            autoplay: {
                delay: 4500,
                disableOnInteraction: !1
            },
            navigation: {
                nextEl: ".j-service-next",
                prevEl: ".j-service-prev"
            },
            breakpoints: {
                480: {
                slidesPerView: 1,
                autoHeight: true,
                },
                640: {
                slidesPerView: 1,
                autoHeight: true,
                },
                768: {
                slidesPerView: 1,
                autoHeight: true,
                },
                992: {
                slidesPerView: 2,
                }
            },
        });

        new Swiper(".i-store-slider", {
            slidesPerView: 1,
            loop: !0,
            autoplay: {
                delay: 4500,
                disableOnInteraction: !1
            },
            pagination: '.i-store-pagination',
            paginationClickable: true,
            autoplay: 5000,
            speed: 1800,
            autoHeight: true,
        });

        $(".fancybox").fancybox({
            openEffect: 'none',
            closeEffect: 'none'
        });

        $('.main-nav__click').click(function() {
            $('.main-nav__block').toggleClass('show');
            $('.main-nav__icon-img').toggleClass('open');
            $('.main-nav__icon').toggleClass('open');
        });

        // Particles 2
        if($('#particles-js').length) {
            particlesJS("particles-js", {
                "particles": {
                    "number": {
                        "value": 120,
                        "density": {
                            "enable": true,
                            "value_area": 801.7060304327614
                        }
                    },
                    "color": {
                        "value": "#ffffff"
                    },
                    "shape": {
                        "type": "circle",
                        "stroke": {
                            "width": 0,
                            "color": "#000000"
                        },
                        "polygon": {
                            "nb_sides": 5
                        },
                        "image": {
                            "src": "img/github.svg",
                            "width": 100,
                            "height": 100
                        }
                    },
                    "opacity": {
                        "value": 0.5,
                        "random": false,
                        "anim": {
                            "enable": false,
                            "speed": 1,
                            "opacity_min": 0.1,
                            "sync": false
                        }
                    },
                    "size": {
                        "value": 3,
                        "random": true,
                        "anim": {
                            "enable": false,
                            "speed": 40,
                            "size_min": 0.1,
                            "sync": false
                        }
                    },
                    "line_linked": {
                        "enable": true,
                        "distance": 150,
                        "color": "#ffffff",
                        "opacity": 0.4,
                        "width": 1
                    },
                    "move": {
                        "enable": true,
                        "speed": 3,
                        "direction": "none",
                        "random": true,
                        "straight": false,
                        "out_mode": "out",
                        "bounce": false,
                        "attract": {
                            "enable": false,
                            "rotateX": 600,
                            "rotateY": 1200
                        }
                    }
                },
                "interactivity": {
                    "detect_on": "canvas",
                    "events": {
                        "onhover": {
                            "enable": false,
                            "mode": "repulse"
                        },
                        "onclick": {
                            "enable": true,
                            "mode": "push"
                        },
                        "resize": true
                    },
                    "modes": {
                        "grab": {
                            "distance": 400,
                            "line_linked": {
                                "opacity": 1
                            }
                        },
                        "bubble": {
                            "distance": 400,
                            "size": 40,
                            "duration": 2,
                            "opacity": 8,
                            "speed": 3
                        },
                        "repulse": {
                            "distance": 200,
                            "duration": 0.4
                        },
                        "push": {
                            "particles_nb": 4
                        },
                        "remove": {
                            "particles_nb": 2
                        }
                    }
                },
                "retina_detect": true
            });
        }
        // end Particles 2

    })
})(jQuery);